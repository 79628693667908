// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ImageComment__commentImage--xQAtp{aspect-ratio:1/1;background-color:#f0f0f0;margin-bottom:20px;text-align:center}.ImageComment__commentImage--xQAtp>img{height:100%;object-fit:contain;vertical-align:bottom;width:100%}.ImageComment__commentBtn--PJK8y{display:grid;gap:15px;grid-template-columns:1fr 2fr;margin-top:20px}", "",{"version":3,"sources":["webpack://./assets/src/js/components/common/ui/form/ImageComment.vue"],"names":[],"mappings":"AAEA,mCACE,gBAAA,CACA,wBAAA,CACA,kBAAA,CACA,iBAAA,CAEA,uCACE,WAAA,CACA,kBAAA,CACA,qBAAA,CACA,UAAA,CAIJ,iCACE,YAAA,CACA,QAAA,CACA,6BAAA,CACA,eAAA","sourcesContent":["@import '@/scss/base/variables';\n\n.commentImage {\n  aspect-ratio: 1 / 1;\n  background-color: #f0f0f0;\n  margin-bottom: 20px;\n  text-align: center;\n\n  > img {\n    height: 100%;\n    object-fit: contain;\n    vertical-align: bottom;\n    width: 100%;\n  }\n}\n\n.commentBtn {\n  display: grid;\n  gap: 15px;\n  grid-template-columns: 1fr 2fr;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"commentImage": "ImageComment__commentImage--xQAtp",
	"commentBtn": "ImageComment__commentBtn--PJK8y"
};
module.exports = ___CSS_LOADER_EXPORT___;
