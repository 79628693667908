// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".update-policy-202403__wrapper--ZDaXv{text-align:center}.update-policy-202403__title--fTv8L{font-size:18px;font-weight:700}.update-policy-202403__lead--Y5yso,.update-policy-202403__title--fTv8L{margin-bottom:16px}.update-policy-202403__logout--bMYFV{margin-top:16px}@media only screen and (max-width:639px){.update-policy-202403__lead--Y5yso{text-align:left}}", "",{"version":3,"sources":["webpack://./assets/src/js/components/permission/update-policy-202403.vue"],"names":[],"mappings":"AAEA,sCACE,iBAAA,CAGF,oCACE,cAAA,CACA,eACA,CAGF,uEAHE,kBAIA,CAOF,qCACE,eAAA,CANA,yCAHF,mCAII,eAAA,CAAA","sourcesContent":["@import '@/scss/base/variables';\n\n.wrapper {\n  text-align: center;\n}\n\n.title {\n  font-size: 18px;\n  font-weight: bold;\n  margin-bottom: 16px;\n}\n\n.lead {\n  margin-bottom: 16px;\n\n  @media only screen and (max-width: $breakpoint-s) {\n    text-align: left;\n  }\n}\n\n.logout {\n  margin-top: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": "update-policy-202403__wrapper--ZDaXv",
	"title": "update-policy-202403__title--fTv8L",
	"lead": "update-policy-202403__lead--Y5yso",
	"logout": "update-policy-202403__logout--bMYFV"
};
module.exports = ___CSS_LOADER_EXPORT___;
